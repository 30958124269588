import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import { MdPhone } from 'react-icons/md';

import { PhoneLink } from '../../PhoneLink/PhoneLink';

import { SPEED_FAST, TIMING_FUNCTION } from '../../../lib/constants/layout';
import { CONFIG } from '../../../lib/constants/config';
import { getConfig } from '../../../lib/helpers/config';
import { footerNavigation } from './footer-navigation';

import { FooterLink, LinkButton } from '../FooterTemplate/FooterTemplate.style';

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),

  ['& > div > div:first-of-type, & > div > div:last-of-type']: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const Headline = styled(Typography)(({ theme }) => ({
  display: 'none',
  color: theme.palette.primary.main,
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    margin: `${theme.spacing(2)} 0 0`,
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  margin: `${theme.spacing(1)} 0 0`,
}));

const HotlineText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  margin: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
}));

const HotlineButton = styled(PhoneLink)(({ theme }) => ({
  // @ts-ignore
  backgroundColor: theme.palette.primary[100],
  borderRadius: 20,
  color: theme.palette.primary.main,
  display: 'inline-flex',
  fontSize: 14,
  height: 40,
  lineHeight: '40px',
  paddingRight: theme.spacing(2),
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
  ['& > span']: {
    alignItems: 'center',
    // @ts-ignore
    backgroundColor: theme.palette.text.white,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    transition: `border-color  ${SPEED_FAST} ${TIMING_FUNCTION}`,
    width: 40,
  },
  ['&:hover > span']: {
    borderColor: theme.palette.primary.main,
  },
}));

interface FooterNavigationProps {
  target: '_blank' | '_self';
}

/**
 * Footer navigation and hotline link
 *
 * @param {Object}  props
 * @param {'_blank'|'_self'} target - The anchor target to use for the links
 */
export const FooterNavigation: React.FC<FooterNavigationProps> = ({
  target,
}) => {
  const phone = getConfig(CONFIG.PHONE) || '';

  return (
    <Wrapper>
      <Grid container>
        {[
          ...footerNavigation.columns.map((column) => (
            <Grid item xs={6} md={3} mb={4} key={column.headline}>
              <Headline variant="h6">{column.headline}</Headline>
              <List>
                {column.list.map((item) => {
                  return item.url ? (
                    <ListItem key={item.url}>
                      <FooterLink
                        className="Footer-listLink"
                        href={item.url}
                        target={target}
                        rel="noopener noreferrer"
                      >
                        {item.label}
                      </FooterLink>
                    </ListItem>
                  ) : (
                    <ListItem key={item.label}>
                      {/* eslint-disable-next-line react/jsx-handler-names */}
                      <LinkButton type="button" onClick={item.handle}>
                        {item.label}
                      </LinkButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )),
          <Grid item xs={3} key="hotline">
            <Headline variant="h6">Social und Hotline</Headline>
            <HotlineText variant="body1">
              Gemeinsam finden wir Ihren passenden Kredit! Kostenlose Hotline.
            </HotlineText>
            <HotlineButton phone={phone}>
              <span>
                <MdPhone />
              </span>
              <div>{phone}</div>
            </HotlineButton>
          </Grid>,
        ]}
      </Grid>
    </Wrapper>
  );
};
