import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';

import { ThemeProvider } from '@mui/material/styles';
import { InitOptions } from '@finanzcheck/one-segment/dist/init';
import initOneSegment, {
  preInit,
  cookieBannerSnippet,
} from '@finanzcheck/one-segment';

import { getTheme, Tenant } from '@finanzcheck/teal-mui-theme';
import { initConfig } from 'lib/helpers/config';
import { DEFAULT_CONFIG } from 'lib/constants/config';
import { setSessionId } from 'lib/session/session';
import { websiteGlobal } from 'lib/platform/server';
import { TrackingEvents } from 'lib/tracking/events';
import { track } from 'lib/tracking/helper';
import { useOptimizely, OptimizelyProvider } from 'lib/optimizely';
import { GlobalStyles } from 'components/GlobalStyles/GlobalStyles';
import { ErrorBoundary } from 'components/Cookies/Bugsnag';
import { PageConfigProvider } from 'contexts/PageConfigContext';
import { LoanSelectionProvider } from 'contexts/LoanSelectionContext';
import { BestOfferProvider } from 'contexts/BestOfferContext';
import { EkomiProvider } from 'contexts/EkomiContext';
import { isLive } from 'lib/helpers/environmentsHelper';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';

import ErrorPage from './_error';
import '../css/fonts.css';

/**
 * called after rendering
 */
const initAppEffect = (
  url: string,
  setIsAnalyticsReady: (isAnalyticsReady: boolean) => void,
) => {
  // WS-53 we had a lot of strange unhandle promised rejections
  // This does not work for all browsers,
  // but will stop them from further bubbling and report them

  websiteGlobal.addEventListener('unhandledrejection', (error) => {
    track(TrackingEvents.BS_NOTIFY, {
      error,
      metaData: {
        referrer: document.referrer,
      },
      context: 'unhandled promise rejection found',
    });
    // stop this from surfacing anymore
    error.preventDefault();
  });

  preInit();
  setIsAnalyticsReady(true);
  track(TrackingEvents.PAGE_ONVISIT, { buildUrl: url });
};

const WebsiteFinanzcheckApp = ({ Component, pageProps }: AppProps) => {
  const theme = getTheme(Tenant.Finanzcheck);
  const [isAnalyticsReady, setIsAnalyticsReady] = useState<boolean>(false);

  const isTrackingDebug = !isLive();
  const hasUrlParams = Boolean(pageProps?.url?.split('?')[1]);

  let prerenderCompleted = false;
  const setPrerenderCompleted = (value: boolean) =>
    (prerenderCompleted = value);

  const optimizely = useOptimizely(pageProps);

  useEffect(() => {
    setSessionId();
    // NOTE: if clause will be triggered on second mount
    if (prerenderCompleted) {
      // NOTE: calls unmount if second mount unmounts -> clean up value
      return () => {
        setPrerenderCompleted(false);
      };
    }
    setPrerenderCompleted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // done on purpose to only run once

  useEffect(() => {
    initAppEffect(pageProps?.url, setIsAnalyticsReady);
    // NOTE: Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode?.removeChild(jssStyles);
    }
  }, [pageProps?.url]);

  const segmentHost = process.env.SEGMENT_HOST;

  const initOneSegmentOptions: InitOptions = {
    debug: isTrackingDebug,
    initialPageTrack: false,
    teamName: 'catapult',
    sessionCookieDomain: process.env.COOKIE_BASE_URL,
    segmentHost,
  };

  initConfig({ ...DEFAULT_CONFIG });

  const children = (
    <>
      <GlobalStyles />
      <AnalyticsProvider value={{ isAnalyticsReady, setIsAnalyticsReady }}>
        <ThemeProvider theme={theme}>
          <PageConfigProvider>
            <OptimizelyProvider
              optimizely={optimizely}
              user={pageProps.user}
              isServerSide={typeof window === 'undefined'}
            >
              <LoanSelectionProvider>
                <BestOfferProvider>
                  <EkomiProvider>
                    {/* Always pass isServerSide in order to fix an unexpected behaviour in optimizely SDK: */}
                    {/* Even though client is ready immediately (due to datafile and user being present), initial state is not ready >_< */}

                    <Component {...pageProps} />
                  </EkomiProvider>
                </BestOfferProvider>
              </LoanSelectionProvider>
            </OptimizelyProvider>
          </PageConfigProvider>
        </ThemeProvider>
      </AnalyticsProvider>

      {(prerenderCompleted || !hasUrlParams) && (
        <>
          <Script
            id="init-one-segment"
            strategy="afterInteractive"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-domain-script={process.env.ONE_TRUST_SCRIPT_ID}
            onLoad={() => {
              initOneSegment(
                process.env.SEGMENT_WRITE_KEY,
                initOneSegmentOptions,
              );
            }}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: cookieBannerSnippet(),
        }}
      />

      {ErrorBoundary ? (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default WebsiteFinanzcheckApp;
