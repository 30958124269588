import { memo } from 'react';

import { FooterTemplate } from '../FooterTemplate/FooterTemplate';

interface FooterMinimalProps {
  datenschutzUrl?: string;
  openLinksInNewTab?: boolean;
  mt?: number;
}

/**
 * Footer component containing only the minimal links
 *
 * @param {Object}  props
 */
export const FooterMinimal: React.FC<FooterMinimalProps> = memo(
  ({ mt, datenschutzUrl }) => (
    <FooterTemplate
      mt={mt}
      datenschutzUrl={datenschutzUrl}
    />
  ),
);

FooterMinimal.displayName = 'FooterMinimal';
