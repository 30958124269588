import { Container, Box } from '@mui/material';

import { FooterWrapper, Spacer } from './FooterTemplate.style';
import { TealFooterUnified } from '@finanzcheck/teal-components';

const footerLinks = {
  agb: '/uber-uns/agb/',
  impressum: '/uber-uns/impressum/',
  datenschutz: '/uber-uns/datenschutz/',
};
interface FooterTemplateProps {
  Navigation?: React.ReactNode;
  mt?: number;
  datenschutzUrl?: string;
}

/**
 * Footer template
 * Renders per default empty and containing only the copyright text and address. Each element can be passed over using composition.
 *
 * @param {Object} props
 * @param {Node}   [props.Navigation=null]   - Footer navigation
 */
export const FooterTemplate: React.FC<FooterTemplateProps> = ({
  Navigation,
  mt,
  datenschutzUrl,
}) => (
  <>
    {Navigation && (
      <FooterWrapper style={{ marginTop: mt }}>
        <Container>{Navigation}</Container>
      </FooterWrapper>
    )}
    <Box
      mt={(theme) => theme.spacing(3)}
      borderTop={(theme) => `1px solid ${theme.palette.grey[300]}`}
    >
      <TealFooterUnified
        origin="finanzcheck"
        oneTrustHandler={() => window?.OneTrust?.ToggleInfoDisplay()}
        footerLinks={{
          ...footerLinks,
          ...(datenschutzUrl && { datenschutz: datenschutzUrl }),
        }}
      />
    </Box>
  </>
);

export { Spacer };
