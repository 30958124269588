import { styled } from '@mui/material/styles';
import { MdLock } from 'react-icons/md';

import {
  SPACE2,
  SPACE3,
  SPACE6,
  SPACE12,
  SMALL_FONT_SIZE,
} from 'lib/constants/layout';
import Grid from '@mui/material/Grid';

const FooterWrapper = styled('footer')`
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  padding: ${SPACE6} 0;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: ${SPACE12};
`;

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const Copyright = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
  flex: '1 0 100%',
  marginTop: SPACE6,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 0 auto',
    marginTop: 0,
    textAling: 'left',
  },
}));

const StyledLock = styled(MdLock, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme, size }) => ({
  display: 'none',
  height: size,
  marginRight: SPACE3,
  verticalAlign: 'baseline !important',
  width: size,
  [theme.breakpoints.up('sm')]: {
    display: 'inline-block',
  },
}));

const Spacer = styled('span')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('sm')]: {
    display: 'inline',
    ['&:not(:last-child)::after']: {
      content: '"•"',
      fontSize: SMALL_FONT_SIZE,
      margin: `0 ${SPACE2}`,
    },
  },
}));

const FooterLink = styled('a')(({ theme }) => ({
  color: theme.palette.grey[600],
  textAlign: 'left',
  textDecoration: 'none',
}));

const LinkButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  display: 'inline',
  margin: 0,
  padding: 0,
  textAlign: 'left',
}));

export {
  ContentWrapper,
  Copyright,
  FooterLink,
  FooterWrapper,
  LinkButton,
  Spacer,
  StyledGrid,
  StyledLock,
};
