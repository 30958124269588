import { memo } from 'react';

import { FooterTemplate } from '../FooterTemplate/FooterTemplate';
import { FooterNavigation } from './FooterNavigation';

interface FooterFullProps {
  openLinksInNewTab?: boolean;
  mt?: number;
  datenschutzUrl?: string;
}

/**
 * Footer component containing the full navigation and social links
 *
 * @param {Object}  props
 * @param {boolean} [props.openLinksInNewTab=false] - Indicates if the links should be open in a new tab (or window)
 */
export const FooterFull: React.FC<FooterFullProps> = memo(
  ({ openLinksInNewTab, mt, datenschutzUrl }) => (
    <FooterTemplate
      mt={mt}
      Navigation={
        <FooterNavigation target={openLinksInNewTab ? '_blank' : '_self'} />
      }
      datenschutzUrl={datenschutzUrl}
    />
  ),
);

FooterFull.displayName = 'FooterFull';
