import React from 'react';

interface AnalyticsContextProps {
  isAnalyticsReady?: boolean;
  setIsAnalyticsReady?: (props: boolean) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextProps>({});
const AnalyticsProvider = AnalyticsContext.Provider;
const AnalyticsConsumer = AnalyticsContext.Consumer;
const useAnalytics = () => React.useContext(AnalyticsContext);

export { AnalyticsProvider, AnalyticsConsumer, useAnalytics };
